import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const verzending = ({ data }) => {
  return (
    <Layout language="nl" en="terms-and-conditions" nl="algemene-voorwaarden">
      <SEO
        title="Algemene Voorwaarden"
        keywords={[`valta`, `lamp`, `licht`, `verzending`]}
      />
      <div className="articleHero gridFullWidth">
        <h1>Algemene Voorwaarden</h1>
      </div>
      <article className="gridFullWidth">
        <div className="articleContent">
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>
        <aside>
          <div className="scrollingMenu">
            <h3>Inhoudsopgave</h3>
            <ol>
              <li>
                <a href="#definities">Definities</a>
              </li>
              <li>
                <a href="#identiteit">Identiteit van de ondernemer</a>
              </li>
              <li>
                <a href="#toepasselijkheid">Toepasselijkheid</a>
              </li>
              <li>
                <a href="#aanbod">Het aanbod</a>
              </li>
              <li>
                <a href="#overeenkomst">De overeenkomst</a>
              </li>
              <li>
                <a href="#herroepingsrecht">Herroepingsrecht</a>
              </li>
              <li>
                <a href="#verplichtingen">
                  Verplichtingen van de consument tijdens de bedenktijd
                </a>
              </li>
              <li>
                <a href="#uitoefening">
                  Uitoefening van het herroepingsrecht door de consument en
                  kosten daarvan
                </a>
              </li>
              <li>
                <a href="#verplichtingen-ondernemer">
                  Verplichtingen van de ondernemer bij herroeping
                </a>
              </li>
              <li>
                <a href="#uitsluiting">Uitsluiting herroepingsrecht</a>
              </li>
              <li>
                <a href="#prijs">De prijs</a>
              </li>
              <li>
                <a href="#nakoming">Nakoming en extra garantie</a>
              </li>
              <li>
                <a href="#levering">Levering en uitvoering</a>
              </li>
              <li>
                <a href="#duurtransacties">
                  Duurtransacties: duur, opzegging en verlenging
                </a>
              </li>
              <li>
                <a href="#betaling">Betaling</a>
              </li>
              <li>
                <a href="#klachtenregeling">Klachtenregeling</a>
              </li>
              <li>
                <a href="#geschillen">Geschillen</a>
              </li>
              <li>
                <a href="#aanvullende-bepalingen">
                  Aanvullende of afwijkende bepalingen
                </a>
              </li>
            </ol>
          </div>
        </aside>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    mdx(frontmatter: { slug: { eq: "algemene-voorwaarden" } }) {
      frontmatter {
        slug
        title
        subtitle
        description
      }
      body
    }
  }
`

export default verzending
